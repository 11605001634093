.gdpr-model-popup {
    position: absolute;
    z-index: 500000;
    top: 12%;
    left: 24%;
    font-family: robotoMedium;
}
.privacy-header {
    margin-left: 106px;
    margin-bottom: 2px;
    margin-top: 24px;
}
.information-text {
    margin-top: 11%;
    margin-bottom: -5px;
    color: white;
    font-weight: normal;
    width: 88%;
    text-align: center;
    margin-left: 7%;
    font-size: 27px;
    line-height: 48px;
    text-shadow: 1px 1px 5px black, -1px 1px 5px black;
}


.GDPR-sprite-sheet-popup { background-size: 100% 100%; width: 915px; height: 421px; }
.GDPR-sprite-sheet-title { width: 500px; height: 52px; background-position: -4px -479px; }
/*.GDPR-sprite-sheet-btn-yes-active { width: 179px; height: 75px; background-position: 0 0;
    background-size: 100% 100%;
    background-repeat: no-repeat; }*/
.GDPR-sprite-sheet-btn-yes-disable { width: 179px; height: 75px; background-position: -4px -535px; }
/* .GDPR-sprite-sheet-btn-yes-active { width: 179px; height: 75px; background-position: -387px -479px; }
.GDPR-sprite-sheet-btn-yes-disable { width: 179px; height: 75px; background-position: -4px -535px; } */
.GDPR-sprite-sheet-check-box { width: 41px; height: 47px; background-position: -187px -535px; }
.GDPR-sprite-sheet-tick-mark { width: 57px; height: 42px; background-position: -232px -535px; }
.closebuuton {
    cursor: pointer;
    position: absolute;
    top: 0;
    right:0;
}
.buttonClass {
    position: absolute;
    width: 220px;
    height: 63px;
    bottom: 90px;
    left: 363px;
    font-weight: bold;
    border: none;
    background-color: transparent;
    background-image: url("../Lobby/images/CTA_ok.png");
    background-size: 100% 100%;
    /*background: linear-gradient(rgb(96, 216, 5) 3%, rgb(219, 248, 7) 7%, rgb(239, 253, 7) 11%, rgb(0, 235, 0) 19%, rgb(2, 189, 12) 29%, rgb(3, 172, 17) 35%, rgb(4, 145, 24) 53%, rgb(5, 120, 31) 70%, rgb(5, 113, 33) 82%, rgb(4, 131, 36) 86%, rgb(3, 155, 40) 91%, rgb(2, 186, 46) 94%, rgb(3, 157, 41) 96%, rgb(3, 157, 41) 96%);*/
    font-size: 29.4522px;
    border-radius: 5.73047px;
    color: white;

}
#buttonClassConfirmation {
    position: absolute;
    bottom: 43px;
    left: 270px;
}
.GDPR-sprite-sheet-btn-yes-active {
    cursor: pointer;
}
.GDPR-sprite-sheet-btn-yes-disable {
    cursor: not-allowed;
    pointer-events: none;
}
.link-text {
    color: #E9F963;
    font-size: 25px;
    text-decoration: underline;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    text-shadow: 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87;
}
.gsn-about-link {
    text-decoration: underline;
    color: white;
    text-shadow: 1px 1px 1px black, -1px 1px 1px black;
}
.select-check-box {
    position: absolute;
    left: 185px;
    top: 9px;
    cursor: pointer;
}
.select-tick {
    position: absolute;
    left: 190px;
    top: 9px;
    z-index: 1;
    cursor: pointer;
}
.select-text {
    color: #fdf4ad;
    font-weight: normal;
    width: 85%;
    text-align: center;
    margin-left: 80px;
    font-size: 27px;
    line-height: 25px;
    text-shadow: 1px 2px 10px black, -1px 2px 10px black;
    padding-top: 20px;
}
.withdraw-link {
    color: white;
    position: absolute;
    bottom: 26px;
    left: 187px;
    font-size: 17px;
}
input:focus{
    outline: none;
}

@media screen and (max-width: 841px){
    @-ms-viewport { width: 320px; }
    .gdpr-model-popup {
        left: 1%;
    }
}

@media screen and (min-width: 841px) and (max-width: 880px){
    @-ms-viewport { width: 841px; }
    .gdpr-model-popup {
        left: 6%;
    }
}

@media screen and (min-width: 880px) and (max-width: 978px){
    @-ms-viewport { width: 880px; }
    .gdpr-model-popup {
        left: -2%;
    }
}
@media screen and (min-width: 978px) and (max-width: 1100px){
    @-ms-viewport { width: 978px; }
    .gdpr-model-popup {
        left: 5%;
    }
}
@media screen and (min-width: 1100px) and (max-width: 1173px){
    @-ms-viewport { width: 1100px; }
    .gdpr-model-popup {
        left: 12%;
    }
}
@media screen and (min-width: 1173px) and (max-width: 1401px){
    @-ms-viewport { width: 1173px; }
    .gdpr-model-popup {
        left: 12%;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1586px){
    @-ms-viewport { width: 1401px; }
    .gdpr-model-popup {
        left: 19%;
    }
}

@media screen and (min-width: 1586px) and (max-width: 1760px){
    @-ms-viewport { width: 1586px; }
    .gdpr-model-popup {
        left: 21%;
    }
}

@media screen and (min-width: 1760px) and (max-width: 1960px){
    @-ms-viewport { width: 1760px; }
    .gdpr-model-popup {
        left: 22%;
    }
}

@media screen and (min-width: 1960px) and (max-width: 2230px){
    @-ms-viewport { width: 1960px; }
    .gdpr-model-popup {
        left: 22%;
    }
}
@media screen and (min-width: 2230px) and (max-width: 2640px){
    @-ms-viewport { width: 2230px; }
    .gdpr-model-popup {
        left: 22%;
    }
}

@media screen and (min-width: 2640px) and (max-width: 3082px){
    @-ms-viewport { width: 2640px; }
    .gdpr-model-popup {
        left: 24%;
    }
}

@media screen and (min-width: 3082px) and (max-width: 3174px){
    @-ms-viewport { width: 3082px; }
    .gdpr-model-popup {
        left: 28%;
    }
}

@media screen and (min-width: 3174px) and (max-width: 3219px){
    @-ms-viewport { width: 3082px; }
    .gdpr-model-popup {
        left: 28%;
    }
}

@media screen and (min-width: 3219px) and (max-width: 3520px){
    @-ms-viewport { width: 3219px; }
    .gdpr-model-popup {
        left: 28%;
    }
}

@media screen and (min-width: 3521px){
    @-ms-viewport { width: 3520px; }
    .gdpr-model-popup {
        left: 28%;
    }
}
