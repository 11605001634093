
.lobby_notready_placeholder{
    text-align: center;
    width: 100%;
    position: absolute;
    right: 18%;
    height: 46%;
    z-index: 2;
}
.lobby_notready_placeholder_other{
    text-align: center;
    position: absolute;
    right: 18%;
    width: 100%;
    height: 46%;
    z-index: 2;
}
.blanck_screen_error{
    top: 10%;
    z-index: 9;
}
.other_screen_error{
    top: 30%;
}


.text_2 {
    margin-left: 21%;
    font-family: 'robotoBlack';
    color: #ffffff;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}



.text1{
    margin-left: 18%;
    width: 93%;
    font-family: 'robotoBlack';
    color: #ffffff;
    font-size: 1.5em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.text_wifi{
    width:93%;
    margin-top: 0px;
    font-size: 1em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;

}

.text_seam_err{
    width:93%;
    margin-top: 0px;
    font-size: 1em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;

}

.text3{
    width:93%;
    margin-left: 18%;
    font-size: 1em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.button_container{
    position: relative;
    left: 3%;
}


.text_reload{
    width: 68%;
    margin-left: 12%;
        font-size: 1em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;

}

.button{
    font-family: 'robotoBlack';
    margin-left: 37%;
    background: #35e340;
    background-image: -webkit-linear-gradient(top, #35e340, #23a317);
    background-image: -moz-linear-gradient(top, #35e340, #23a317);
    background-image: -ms-linear-gradient(top, #35e340, #23a317);
    background-image: -o-linear-gradient(top, #35e340, #23a317);
    background-image: linear-gradient(to bottom, #35e340, #23a317);
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    text-shadow: 0px 6px 11px #333333;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.5em;
    margin-top: 5%;
 }
.button:hover {
    background: #65e66b;
    background-image: -webkit-linear-gradient(top,#3f8a36, #65e66b);
    background-image: -moz-linear-gradient(top,#3f8a36, #65e66b);
    background-image: -ms-linear-gradient(top,#3f8a36, #65e66b);
    background-image: -o-linear-gradient(top,#3f8a36, #65e66b);
    background-image: linear-gradient(to bottom,#3f8a36, #65e66b);
    text-decoration: none;
}

.button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.imgblankscreen{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
}
.text_blankscreen{
    width:93%;
    color: #f0f006;
    margin-top: 0px;
    font-size: 1.4em;
    text-shadow: none;
    background-color: #04043c;
}


