
@font-face {
    font-family: 'robotoBlack';
    src: url('../common/fonts/Roboto-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.lets-play{
    width: 20%;
    height: 10%;
    left: 29%;
    top:30%;
    position: absolute;
    z-index: 3;
}

.result-congrats-placeholder{
  width: 65%;
  height: 16%;
  left: 18%;
  position: absolute;
  z-index: 2;
  top: 26%;
}

.result-youwon-placeholder{
    width: 43%;
    height: 14%;
    left: 28%;
    position: absolute;
    z-index: 2;
    top: 13%;
    /* margin-bottom: 207%; */
}

.won-placeholder{
  margin-top: -43%;
  animation: zoomIn .2s;
  -webkit-animation: zoomIn .2s;
  -moz-animation: zoomIn .2s;
  -o-animation: zoomIn .2s;
margin-left:2%;

}

.guessed-text-placeholder{
  font-family: 'robotoBlack';
  color: #f3d300;
  text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
  font-size: 2.6em;
  animation: zoomIn .2s;
  -webkit-animation: zoomIn .2s;
  -moz-animation: zoomIn .2s;
  -o-animation: zoomIn .2s;
}

.you_won{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: heartBeat 1.2s;
    -webkit-animation: heartBeat 1.2s;
    -moz-animation: heartBeat 1.2s;
    -o-animation: heartBeat 1.2s;
}


.congratulations{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: heartBeat 1.2s;
    -webkit-animation: heartBeat 1.2s;
    -moz-animation: heartBeat 1.2s;
    -o-animation: heartBeat 1.2s;
}
.better_luck{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: heartBeat 1.2s;
    -webkit-animation: heartBeat 1.2s;
    -moz-animation: heartBeat 1.2s;
    -o-animation: heartBeat 1.2s;
}
.better_luck_placeholder{
      width: 80%;
    height: 14%;
    left: 13%;
    position: absolute;
    z-index: 2;
    top: 42%;
}
.awesome{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: heartBeat 1.2s;
    -webkit-animation: heartBeat 1.2s;
    -moz-animation: heartBeat 1.2s;
    -o-animation: heartBeat 1.2s;
}

.awesome_result_placeholder{
  width: 56%;
  height: 14%;
  left: 22%;
  position: absolute;
  z-index: 2;
  top: 10%;
}

.text_white {
  font-family: 'robotoBlack';
  font-size: 4.5em;
  color: #ffffff;
  text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.text_score {

  font-family: 'robotoBlack';
  top: 60%;
  position: absolute;
  font-size: 3.2em;
  left: 42%;
  color: #ffffff;
  text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}
.text_yellow_score{
  font-family: 'robotoBlack';
  font-size: 3em;
  color: #f3d300;
  text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.text_yellow {
  font-family: 'robotoBlack';
  font-size: 4.5em;
  color: #f3d300;
  margin-right: 30px;
  text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.tokens_number_style{
  margin-left: -91%;
}

.tokens_str_style{
  left: 65%;
}
.token-bar-placeholder{
    width: 59%;
    height: 16%;
    left: 23%;
    position: absolute;
    z-index: 2;
    top: 68%;

}
.token-bar{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.text_1 {
    left: 30%;
    font-family: 'robotoBlack';
    font-size: 3.5em;
    color: #f3d300;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.coin_placeholder{
    width: 27%;
    height: 15%;
    left: 20%;
    position: absolute;
    z-index: 2;
    top: 50%;
}

.coin-placeholder{
    width: 16%;
    height: 86%;
    left: 8%;
    position: absolute;
    top: 6%;
}
.coin{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}


.text_2 {
    font-family: 'robotoBlack';
    font-size: 4.5em;
    color: #ffffff;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
    animation: zoomIn 1s;
    -webkit-animation: zoomIn 1s;
    -moz-animation: zoomIn 1s;
    -o-animation: zoomIn 1s;
}



.text_4 {
    font-family: 'robotoBlack';
    font-size: 4.5em;
    color: #ffffff;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
    animation: zoomIn 1s;
    -webkit-animation: zoomIn 1s;
    -moz-animation: zoomIn 1s;
    -o-animation: zoomIn 1s;
}

.lose-msg-placeholder{
  height: 46%;
  width: 59%;
  left: 21%;
  position: absolute;
  z-index: 2;
  top: 30%;
}


.play_again_button{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: fadeIn;
    -webkit-animation: fadeIn;
    -moz-animation: fadeIn;
    -o-animation: fadeIn;
}



.lets_play_button{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: fadeIn;
  -webkit-animation: fadeIn;
  -moz-animation: fadeIn;
  -o-animation: fadeIn;

}
.lets_play_placeholder{
       position: absolute;
    z-index: 0;
    width: 26%;
    height: 13%;
    top: 79%;
    left: 38%;

}

.collect_play_button_placeholder{
 position: absolute;
      z-index: 2;
    width: 29%;
    height: 16%;
    top: 79%;
    left: 36%;

}
.high-low-game{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.high-low-game-placeholder {
  z-index: 1;
  width: 59%;
  height: 69%;
  position: absolute;
  left: 20%;
  top: 14%;
}

.high-lo-small-icon {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.high-lo-small-icon-placeholder {
  width: 28%;
  height: 25%;
  left: 35%;
  top: -7%;
  position: absolute;
}

@keyframes zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
  }


  @-webkit-keyframes heartBeat {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    14% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }

    28% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    42% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }

    70% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes heartBeat {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    14% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }

    28% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    42% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }

    70% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }


@-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  .total_win_placeholder{
    width: 36%;
    height: 17%;
    left: 31%;
    top: 56%;
    position: absolute;
  }

  .total_win_big{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: bounceInUp 2s;
    -webkit-animation: bounceInUp 2s;
    -moz-animation: bounceInUp 2s;
    -o-animation: bounceInUp 2s;
  }

  .guessed_text_1{
    margin-left: -2%;
  }

  .guessed_text_2{
    margin-left: -5%;
}
.high-low-game-icon{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: bounceInUp 2s;
    -webkit-animation: bounceInUp 2s;
    -moz-animation: bounceInUp 2s;
    -o-animation: bounceInUp 2s;
}

.play_again_placeholder{
       position: absolute;
    z-index: 2;
    width: 28%;
    height: 14%;
    top: 64%;
    left: 37%;
}
.coins_fly_placeholder{
  position: absolute;
  width: 90%;
  height: 87%;
  top: 3%;
  left: 6%;
  animation: zoomIn 2s;
    -webkit-animation: zoomIn 2s;
    -moz-animation: zoomIn 2s;
    -o-animation: zoomIn 2s;
}

.coins_fly{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

.confiti_placeholder{
  position: absolute;
  width: 96%;
  height: 92%;
  top: 3%;
  left: 2%;
  animation: zoomIn 2s;
    -webkit-animation: zoomIn 2s;
    -moz-animation: zoomIn 2s;
    -o-animation: zoomIn 2s;

}


.confiti{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

.flex-style{
  display:-webkit-flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid yellowgreen; */
}

@media screen and (min-width: 0px) and (max-width: 300px){
@-ms-viewport { width: 841px; }

  .text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }

}
@media screen and (min-width: 250px) and (max-width: 750px){
@-ms-viewport { width: 841px; }

  .text_white{
  font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }


}
@media screen and (min-width: 751px) and (max-width: 880px){
@-ms-viewport { width: 841px; }

  .text_white{
  font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }

}
@media screen and (min-width: 880px) and (max-width: 978px){
@-ms-viewport { width: 880px; }

  .text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }
  .text_score{
    font-size: 1.8em;
  }
}
@media screen and (min-width: 978px) and (max-width: 1100px){
@-ms-viewport { width: 978px; }

  .text_white{
    font-size: 4em;
  }
 .text_yellow{
    font-size: 4em;
  }
  .text_score{
    font-size: 2em;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1173px){
@-ms-viewport { width: 1100px; }

 .text_white{
    font-size: 4.5em;
  }
  .text_yellow{
    font-size: 4.5em;
  }
  .text_score{
    font-size: 2.2em;
  }
}
@media screen and (min-width: 1173px) and (max-width: 1401px){
@-ms-viewport { width: 1173px; }

 .text_white{
    font-size: 4.5em;
  }
  .text_yellow{
    font-size: 4.5em;
  }
   .text_score{
    font-size: 2.3em;
  }


}
@media screen and (min-width: 1401px) and (max-width: 1586px){
@-ms-viewport { width: 1401px; }

.text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }

}
@media screen and (min-width: 1586px) and (max-width: 1760px){
@-ms-viewport { width: 1586px; }
 .text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }
}
@media screen and (min-width: 1760px) and (max-width: 1960px){
@-ms-viewport { width: 1760px; }
  .text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }

  }
@media screen and (min-width: 1960px) and (max-width: 2230px){
@-ms-viewport { width: 1960px; }
.text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }

}
@media screen and (min-width: 2230px) and (max-width: 2640px){
@-ms-viewport { width: 2230px; }

  .text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }

}
@media screen and (min-width: 2640px) and (max-width: 3082px){
@-ms-viewport { width: 2640px; }
.text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }
}
@media screen and (min-width: 3082px) and (max-width: 3174px){
@-ms-viewport { width: 3082px; }
 .text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }
}
@media screen and (min-width: 3174px) and (max-width: 3219px){
@-ms-viewport { width: 3082px; }

.text_white{
    font-size: 4em;
  }
  .text_yellow{
    font-size: 4em;
  }

.text_score{
  font-size: 3em;
}
}
@media screen and (min-width: 3219px) and (max-width: 3520px){
@-ms-viewport { width: 3219px; }

}
@media screen and (min-width: 3521px){
@-ms-viewport { width: 3520px; }
}
