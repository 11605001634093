/* @import url('https://fonts.googleapis.com/css?family=Montserrat'); */
@font-face {
    font-family: 'robotoBlack';
    src: url('../common/fonts/Roboto-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.high-btn{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: bounceInUp 1s;
    -webkit-animation: bounceInUp 1s;
    -moz-animation: bounceInUp 1s;
    -o-animation: bounceInUp 1s;
}

.high-btn-placeholder{
  width: 12%;
  height: 19%;
  left: 45%;
  top: 32%;
  position: absolute;
}

.low-btn-placeholder{
  width: 12%;
  height: 19%;
  left: 45%;
  top: 55%;
  position: absolute;
  z-index: 3;
}

.low-btn{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: bounceInDown 1s;
    -webkit-animation: bounceInDown 1s;
    -moz-animation: bounceInDown 1s;
    -o-animation: bounceInDown 1s;
}


.low-btn-disabled{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.high-btn-disabled{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.text_white {
    font-family: 'robotoBlack';
    font-size: 2em;
    color: #ffffff;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}
.text_yellow {
    font-family: 'robotoBlack';
    font-size: 2.1em;
    color: #f3d300;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}
.title_text_placeholder{
      top: 13%;
    width: 81%;
    position: absolute;
    z-index: 2;
    left: 11%;
   animation: zoomIn .5s;
    -webkit-animation: zoomIn .5s;
    -moz-animation: zoomIn .5s;
    -o-animation: zoomIn .5s;
}


.result_text_placeholder{
    top: 14%;
    position: absolute;
    z-index: 2;
    left: 39%;
    width: 24%;
    height:11%;
  animation: zoomIn .5s;
    -webkit-animation: zoomIn .5s;
    -moz-animation: zoomIn .5s;
    -o-animation: zoomIn .5s;
}
.result_text_placeholder_good_guess{
    top: 14%;
    position: absolute;
    z-index: 2;
    left: 39%;
    width: 25%;
    height:12%;
  animation: zoomIn .5s;
    -webkit-animation: zoomIn .5s;
    -moz-animation: zoomIn .5s;
    -o-animation: zoomIn .5s;
}

.result_text_placeholder_try{
    top: 15%;
    position: absolute;
    z-index: 2;
    left: 39%;
    width: 24%;
    height: 10%;
  animation: zoomIn .5s;
    -webkit-animation: zoomIn .5s;
    -moz-animation: zoomIn .5s;
    -o-animation: zoomIn .5s;
}
.high-low-game{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.high-low-game-placeholder {
  z-index: 1;
  width: 59%;
  height: 69%;
  position: absolute;
  left: 20%;
  top: 14%;
}

.nice_one {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
}


.good_guess {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;

}

.great_job{
     width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;

}

.try_again{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;

}


.high-lo-small-icon {

    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.high-lo-small-icon-placeholder {
     width: 25%;
    height: 21%;
    left: 39%;
    top: -6.3%;
    position: absolute;
}



@keyframes zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
  }
  @-webkit-keyframes bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 3000px, 0);
      transform: translate3d(0, 3000px, 0);
    }

    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
    }

    75% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -5px, 0);
      transform: translate3d(0, -5px, 0);
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 3000px, 0);
      transform: translate3d(0, 3000px, 0);
    }

    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
    }

    75% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -5px, 0);
      transform: translate3d(0, -5px, 0);
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-webkit-keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -3000px, 0);
      transform: translate3d(0, -3000px, 0);
    }

    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, 25px, 0);
      transform: translate3d(0, 25px, 0);
    }

    75% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -3000px, 0);
      transform: translate3d(0, -3000px, 0);
    }

    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, 25px, 0);
      transform: translate3d(0, 25px, 0);
    }

    75% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }


  @-webkit-keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }


  .total_win_placeholder{
    width: 26%;
    height: 16%;
    left: 38%;
    top: 79%;
    position: absolute;

  }

  .total_win{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: bounceInUp 2s;
    -webkit-animation: bounceInUp 2s;
    -moz-animation: bounceInUp 2s;
    -o-animation: bounceInUp 2s;
  }

  .score_placeholder{
    width: 59%;
    height: 51%;
    left: 22%;
    top: 35%;
    position: absolute;
    font-size: 1.9em;
  }

@media screen and (min-width: 978px) and (max-width: 1100px){
    @-ms-viewport { width: 978px; }
    .score_placeholder{
      font-size: 1.5em;
    }
}


@media screen and (min-width: 1100px) and (max-width: 1173px){
    @-ms-viewport { width: 1100px; }
    .score_placeholder{
      font-size: 1.7em;
    }
}

@media screen and (min-width: 1173px) and (max-width: 1401px){
  @-ms-viewport { width: 1173px; }

  .score_placeholder{
      font-size: 1.9em;
    }

  }
