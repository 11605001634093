.app-loading-container {
    position: relative;
    top: 1%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9001;
}



.iron-image-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    overflow: hidden;
  }

  .iron-image-preload {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-size: contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-repeat: no-repeat;
  }

  .iron-image-loaded {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-size: cover;

    opacity: 0;

    transition: opacity 1s ease;
  }

  .iron-image-fade-in {
    opacity: 1;
  }
