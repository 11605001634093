
.next-round-icon{
    /*background-image: url('./images/nxt_round.png');*/
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: zoomIn 3s;
    -webkit-animation: zoomIn 3s;
    -moz-animation: zoomIn 3s;
    -o-animation: zoomIn 3s;

  }

  .nxt-round-placeholder{
    height: 18%;
    width: 69%;
    left: 17%;
    position: absolute;
    z-index: 2;
    top: 38%;
  }


@keyframes zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
}


.high-low-game{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.high-low-game-placeholder {
z-index: 1;
width: 59%;
height: 69%;
position: absolute;
left: 20%;
top: 14%;
}

