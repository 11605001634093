.err_top{
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    height: 100%;
    left:1%;
}

.err_content{
    position: relative;
    top:30%;
    color: aliceblue;
    text-align: center;
}
.err_header{
    font-size: 2em;
}
.err_text{
    font-size: 1em;
}

.text_2 {
    text-align: center;
    font-family: 'robotoBlack';
    font-size: 2em;
    color: #ffffff;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.text1{
text-align: center;
    margin-left: 2%;
    width: 93%;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.text2{
    text-align: center;
    width:93%;
    margin-left: 18%;
    margin-top: 0px;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;

}


