.card_symbol_placeholder{
    position: absolute;
      z-index: 4;
    width: 66%;
    height: 66%;
    top: 34%;
    left: 11%;
}

.card_value_placeholder{
    position: absolute;
    top: -8%;
    left: 2%;
    font-size: 5em;

}

.card-displayed-placeholder{
    position: absolute;
    z-index: -2;
    width:100%;
    height:100%;
    left:15%;
  }

.card-flipped{
    width: 100%;
    height: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: zoomInRight 1s;
    -webkit-animation: zoomInRight 1s;
    -moz-animation: zoomInRight 1s;
    -o-animation: zoomInRight 1s;
    -webkit-backface-visibility: hidden;
}
.card-flipped-animation{
    width: 100%;
    height: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: clicker 1s 6;
    -webkit-animation: clicker 1s 6;
    -moz-animation: clicker 1s 6;
    -o-animation: clicker 1s 6;
    -webkit-backface-visibility: hidden;
}
.card-flipped-placeholder{
     left: 66%;
    width: 27%;
    height: 59%;
    z-index: 3;
    position: absolute;
    top: 26%;
}
.cardValue{
    text-transform: uppercase;
    font-size: 4em;

}


.leftCard{
    left: 11%;
    width: 26%;
    height: 55%;
    z-index: 3;
    position: absolute;
    top: 28%;
    animation: zoomInLeft .5s;
    -webkit-animation: zoomInLeft .5s;
    -moz-animation: zoomInLeft .5s;
    -o-animation: zoomInLeft .5s;
}

.rightCard{
    left: 65%;
    width: 26%;
    height: 55%;
    z-index: 3;
    position: absolute;
    top: 28%;
    -webkit-animation: flipInY .3s;
    -moz-animation: flipInY .3s;
    -o-animation: flipInY .3s;
    animation-name: flipInY;
    animation-duration: .3s;


  -webkit-animation-name: flipInY .3s;
  -webkit-animation-duration: flipInY .3s;
  -o-animation-name: flipInY .3s;
  -o-animation-duration: flipInY .3s;

}


@-webkit-keyframes zoomInLeft {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
      transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
      transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
  }

  @keyframes zoomInLeft {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
      transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
      transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
  }



@keyframes clicker {
	0%,
	100% {
    transform: rotate(-4deg);
    -webkit-transform: rotate(-4deg);
    -webkit-backface-visibility: hidden;
	}
	5%,
	10%,
	16%,
	23%,
	31%,
	40%,
	50%,
	61%,
	73%,
	86% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -webkit-backface-visibility: hidden;
	}
	7.5%,
	13%,
	20.5%,
	27%,
	35.5%,
	45%,
	55.5%,
	67%,
	79.5% {
    transform: rotate(4deg);
    -webkit-transform: rotate(4deg);
    -webkit-backface-visibility: hidden;
	}



}

@-webkit-keyframes flipInY {
    from {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }

    40% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    60% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
      transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
      opacity: 1;
    }

    80% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }

    to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }


@-webkit-keyframes zoomInRight {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
      transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
      transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
  }

  @keyframes zoomInRight {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
      transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
      transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
  }

.flex-style-value{
    width: 100%;
    height: 25%;
    display:-webkit-flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:flex;
    align-items: center;
    justify-content: left;

}
.flexbox{

  height: 100%;
  width: 100%;
    display:-webkit-flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:flex;
	flex-direction: column;
	flex-wrap: wrap;
}


.flex-style-symbol{
    width: 90%;
    height: 75%;

}



@media screen and (min-width: 0px) and (max-width: 300px){
@-ms-viewport { width: 841px; }


}
@media screen and (min-width: 250px) and (max-width: 750px){
@-ms-viewport { width: 841px; }

  .text_white{
  font-size: 3.5em;
  }
  .text_yellow{
    font-size: 3.5em;
  }

}
@media screen and (min-width: 751px) and (max-width: 880px){
@-ms-viewport { width: 841px; }

  .text_white{
  font-size: 3.5em;
  }
  .text_yellow{
    font-size: 3.5em;
  }

}
@media screen and (min-width: 880px) and (max-width: 978px){
@-ms-viewport { width: 880px; }


}
@media screen and (min-width: 978px) and (max-width: 1100px){
@-ms-viewport { width: 978px; }
  .cardValue{
    font-size: 3em;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1173px){
@-ms-viewport { width: 1100px; }
 .cardValue{
    font-size:4em;
  }
}
@media screen and (min-width: 1173px) and (max-width: 1401px){
@-ms-viewport { width: 1173px; }
  .cardValue{
    font-size:4em;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1586px){
@-ms-viewport { width: 1401px; }


}
@media screen and (min-width: 1586px) and (max-width: 1760px){
@-ms-viewport { width: 1586px; }

}
@media screen and (min-width: 1760px) and (max-width: 1960px){
@-ms-viewport { width: 1760px; }

}
@media screen and (min-width: 1960px) and (max-width: 2230px){
@-ms-viewport { width: 1960px; }

}
@media screen and (min-width: 2230px) and (max-width: 2640px){
@-ms-viewport { width: 2230px; }


}
@media screen and (min-width: 2640px) and (max-width: 3082px){
@-ms-viewport { width: 2640px; }

}
@media screen and (min-width: 3082px) and (max-width: 3174px){
@-ms-viewport { width: 3082px; }

}
@media screen and (min-width: 3174px) and (max-width: 3219px){
@-ms-viewport { width: 3082px; }

}
@media screen and (min-width: 3219px) and (max-width: 3520px){
@-ms-viewport { width: 3219px; }

}
@media screen and (min-width: 3521px){
@-ms-viewport { width: 3520px; }
}
