.menu_button  {
	position: relative;
	/*background-image: url('./images/menu_icon_active.png');*/
	background-size: 100% 100%;
	margin-left: -65%;
    top: 14%;
    cursor: pointer;
    width: 83%;
    height: 85%;
}

.menu-button:hover{
	display: block;
	width: 75%;
    margin-top: 25%;
    margin-left: -47%;
   }


.container {
	display: block;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 62px;
	height: 100px;
	background-position: -138px -168px;
	z-index: 100;
	position: absolute;
	top: 67%;
    left: -16%;

}

.container-block-drop-3 {
	display: block;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 65px;
	height: 120px;
	background-position: -72px -142px;
	z-index: 100;
	position: absolute;
	top: 67%;
    left: -16%;
}

.container-block-drop-2 {
	display: block;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
    width: 71px;
    height: 91px;
    background-position: -133px -165px;
    z-index: 100;
    position: absolute;
    top: 83%;
    left: -20%;
}
.mute-button {
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 26px;
	height: 26px;
	background-position: -204px -166px;
	margin-top: 14px;
	margin-left: 22px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	display: block;
	background-color: transparent;
	border: 0
}
.unmute-button {
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 26px;
	height: 26px;
    background-position: -205px -197px;
    margin-top: 16px;
    margin-left: -2px;
    -webkit-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
    cursor: pointer;
    background-color: transparent;
    border: 0;
}


.unmute-button:hover {
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 26px;
	height: 26px;
	background-position: -204px -226px;
	margin-top: 14px;
	margin-left: 8px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}
.token-history-button{
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 37px;
	height: 29px;
	background-position: -190px -138px;
	margin-top: 9px;
	margin-left: 17px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}

.token-history-button:hover{
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 37px;
	height: 29px;
	background-position: -151px -265px;
	margin-top: 9px;
	margin-left: 17px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}
.sfx-button {
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 36px;
	height: 18px;
	background-position: -113px -273px;
	margin-top: 13px;
	margin-left: -5px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}


.disabled-sfx-button {
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 36px;
	height: 18px;
	background-position: -73px -273px;
	margin-top: 13px;
	margin-left: -4px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}
.sfx-button:hover {
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 36px;
	height: 18px;
	background-position: -153px -130px;
	margin-top: 14px;
	margin-left: -10px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}

.consent-button {
    opacity: 100;
    background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 36px;
	height: 32px;
	background-position: -192px -69px;
	margin-top: 1px;
	margin-left: -5px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
    border: 0;
    font-size:8pt;
}


.disabled-consent-button {
    opacity: 100;
    background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 36px;
	height: 32px;
	background-position: -192px -2px;
	margin-top: 11px;
	margin-left: -8px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}
.consent-button:hover {
    opacity: 100;
    background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 36px;
	height: 32px;
	background-position: -192px -38px;
	margin-top: 1px;
	margin-left: -8px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}
.gdpr-button{
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 26px;
	height: 30px;
	background-position: -192px -134px;
	margin-top: 8px;
	margin-left: -4px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}

.gdpr-button:hover{
	opacity: 100;
	background-repeat: no-repeat;
	/*background-image: url('./images/menu-dropdown-spritesheet.png');*/
	width: 26px;
	height: 27px;
	background-position: -198px -104px;
	margin-top: 8px;
	margin-left: -4px;
	-webkit-transition: opacity 1s;
	-moz-transition: opacity 1s;
	-o-transition: opacity 1s;
	transition: opacity 1s;
	cursor: pointer;
	background-color: transparent;
	border: 0
}

.settings-placeholder{

    position: relative;
    z-index: 19;
    height: 58%;
    -webkit-flex:1;
    -webkit-box-flex:1;
    -moz-flex:1;
    -moz-box-flex:1;
    -ms-flex:1;
    flex:1
}

  @media screen and (min-width: 1760px) and (max-width: 1960px){
  @-ms-viewport { width: 1760px; }
	.settings-placeholder{
		top: 2%;
	}
}
