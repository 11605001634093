.progress-bar-main-div {
    position: relative;
}

@font-face {
    font-family: 'robotoBlack';
    src: url('../common/fonts/Roboto-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.progress-bar {
    position: absolute;
    height: 69%;
    width: 98%;
    border-radius: -9px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 2%;
}

.progress-bar-windows {
    position: absolute;
    height: 69%;
    width: 98%;
    border-radius: -9px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 2%;
}


.filler {
    position: relative;
    height: 64%;
    width: 710px;
    min-width: 4%;
    /* border-radius: -9px; */
    border-radius: 15px;
    background-repeat: no-repeat;
    -webkit-transition: width 0.2s ease-in;
    -o-transition: width 0.2s ease-in;
    transition: width 0.2s ease-in;
    margin-left: 1%;
    top: 9.003%;
    background-size: 100% 100%;
}

.progress-bar-base {
    height: 15%;
    border-radius: inherit;
    background-repeat: no-repeat;
    background-size: 100% 93%;
    width: 47%;
    margin-top: 2%;
    margin-left: 40%;
    position: relative;
}

.text-style {
    font-size: 0.9em;
    font-family: 'robotoBlack';
    text-shadow: -1px -1px 0 #410352, 1px 1px 0 #410352, 1px -1px 0 #410352, -1px 1px 0 #410352;
    color: #fefefe;
    margin-top: -2%;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    z-index: 2;
    left: 44%;
    /* letter-spacing: 1px; */
}
.text_loader {
    height: 75%;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    justify-content: center;
}
.progress-animate {
    right: 30px;
    top: -10px;
}
@keyframes play {
    100% {
        background-position: -7740px;
        animation-iteration-count: 0;
    }
}

.progress-animate-sprite {
    width: 129px;
    height: 26px;
    z-index: 1;
    top: 2px;
    right: -0.9%;
    position: absolute;

    animation-name: play;
    animation-duration: 1s;
    timing-function: steps(60);
    animation-iteration-count: infinite;

    -webkit-animation-name: play;
    -webkit-animation-timing-function: steps(60);
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;

    -o-animation-name: play;
    -o-animation-duration: 1s;
    -o-animation-timing-function: steps(60);
    -o-animation-iteration-count: infinite;
}


.progress-animate-sprite-windows{
    width: 129px;
    height: 26px;
    z-index: 1;
    top: 2px;
    right: -0.9%;
    position: absolute;

    animation-name: play;
    animation-duration: 1s;
    timing-function: steps(60);
    animation-iteration-count: infinite;

    -webkit-animation-name: play;
    -webkit-animation-timing-function: steps(60);
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;

    -o-animation-name: play;
    -o-animation-duration: 1s;
    -o-animation-timing-function: steps(60);
    -o-animation-iteration-count: infinite;
}

@media screen and (min-width: 751px) and (max-width: 880px) {
    @-ms-viewport {
        width: 841px;
    }

    .progress-animate-sprite {
        top: -2px;
    }
    .progress-bar {
        /* background-color: rebeccapurple; */
    }
}
@media screen and (max-width: 841px) {
    @-ms-viewport {
        width: 320px;
    }

    .progress-animate-sprite {
        top: -2px;
        right:-1.9%;
    }
    .progress-bar {
        /* background-color: violet; */
        height: 78%;
    }
}

@media screen and (min-width: 880px) and (max-width: 978px) {
    @-ms-viewport {
        width: 880px;
    }

    .progress-animate-sprite {
        top: -2px;
    }
    .progress-bar {
        /* background-color: black; */
    }
}

@media screen and (min-width: 978px) and (max-width: 1100px) {
    @-ms-viewport {
        width: 978px;
    }

    .progress-animate-sprite {
        top: -2px;
        height :26px;
        right: -1.5%;
    }
    .progress-bar {
        /* background-color: antiquewhite; */
    }
}

@media screen and (min-width: 1100px) and (max-width: 1173px) {
    @-ms-viewport {
        width: 1100px;
    }
    .progress-animate-sprite {
        top: -2px;
    }
    .text-style {
        font-size: 0.8em;
    }
    .progress-bar{
        /* background-color: brown; */
        /* height: 62%; */
    }
}

@media screen and (min-width: 1173px) and (max-width: 1401px) {
    @-ms-viewport {
        width: 1173px;
    }
    .text-style {
        font-size: 1em;
    }
    .filler {
        top: 8.003%;
    }
    .progress-animate-sprite {
        top: 0px;
    }
    .progress-bar{
        /* background-color: #410352; */
        height: 62%;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1586px) {
    @-ms-viewport {
        width: 1401px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite {
        top: 0.2px;
        height: 26px;
        right: -0.9%;
    }
    .progress-bar{
        height: 60%;
        /* background-color: aliceblue; */
    }
}

@media screen and (min-width: 500px) and (max-width: 690px) {
    @-ms-viewport {
        width: 1600px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite {
        top: 0px;
        right: -0.7%;
    }
    .progress-bar {
        /* background-color: palegreen; */
    }
}

@media screen and (min-width: 1680px) and (max-width: 1760px) {
    @-ms-viewport {
        width: 1600px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite {
        top: 0px;
        right: -0.7%;
    }
    .progress-bar {
        /* background-color: palegreen; */
    }

}
/*
@media screen and (min-width: 1586px) and (max-width: 1760px) {
    @-ms-viewport {
        width: 1586px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite {
        top: 4px;
        right: -0.7%;
    }
} */

@media screen and (min-width: 1760px) and (max-width: 1960px) {
    @-ms-viewport {
        width: 1760px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite {
        top: 3px;
        height: 26px;
        background-repeat: no-repeat;
        right: -0.7%;
    }
    .progress-bar{
        height: 55%;
        /* background-color: red; */
    }
}

@media screen and (min-width: 1960px) and (max-width: 2230px) {
    @-ms-viewport {
        width: 1960px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite {
        top: 2px;
        height: 26px;
        background-repeat: no-repeat;
        right: -0.9%;
    }

    .progress-bar {
        /* background-color: khaki; */
    }
}
@media screen and (min-width: 2230px) and (max-width: 2640px) {
    @-ms-viewport {
        width: 2230px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite {
        top: 2px;
        height: 27px;
        background-repeat: no-repeat;
        right: -0.9%;
    }
    .progress-bar {
        /* background-color: paleturquoise; */
        height: 58%;
    }
}

@media screen and (min-width: 2640px) and (max-width: 3082px) {
    @-ms-viewport {
        width: 2640px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite{
        top: 3px;
        height: 26px;
    }
    .progress-bar {
        /* background-color: beige; */
        height: 60%;
    }
}

@media screen and (min-width: 3082px) and (max-width: 3174px) {
    @-ms-viewport {
        width: 3082px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-bar {
        /* background-color: palevioletred; */
    }
}

@media screen and (min-width: 3174px) and (max-width: 3219px) {
    @-ms-viewport {
        width: 3082px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-bar {
        /* background-color: palegreen; */
    }
}

@media screen and (min-width: 3219px) and (max-width: 3520px) {
    @-ms-viewport {
        width: 3219px;
    }
    .text-style {
        margin-top: -1.7%;
    }

    .progress-bar {
        /* background-color: powderblue; */
    }
}

@media screen and (min-width: 3521px) {
    @-ms-viewport {
        width: 3520px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-bar {
        /* background-color: pink; */
        height: 60%;
    }
    .progress-animate-sprite{
        top: 4pxpx;
        height: 26px;
        right:-6px;
    }
}


/** Windows **/



@media screen and (min-width: 751px) and (max-width: 880px) {
    @-ms-viewport {
        width: 841px;
    }

    .progress-animate-sprite-windows{
        top: -2px;
    }
    .progress-bar-windows {
        /* background-color: olive; */
        height: 83%;
    }
}
@media screen and (max-width: 841px) {
    @-ms-viewport {
        width: 320px;
    }

    .progress-animate-sprite-windows {
        top: -2.5px;
        right:-0.7%;
        height: 20px;
    }
    .progress-bar-windows {
        /* background-color: fuchsia; */
        height: 86%;
    }
}

@media screen and (min-width: 880px) and (max-width: 978px) {
    @-ms-viewport {
        width: 880px;
    }

    .progress-animate-sprite-windows {
        top: -2px;
    }
    .progress-bar-windows {
        /* background-color: firebrick; */
        height: 78%;
    }
}

@media screen and (min-width: 978px) and (max-width: 1100px) {
    @-ms-viewport {
        width: 978px;
    }

    .progress-animate-sprite-windows {
        top: -2px;
        height :26px;
        right: -0.79%;
    }

    .progress-bar-windows {
        /* background-color: plum; */
        height: 74%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1173px) {
    @-ms-viewport {
        width: 1100px;
    }
    .progress-animate-sprite-windows {
        top: -2px;
    }
    .text-style {
        font-size: 0.8em;
    }
    .progress-bar-windows {
        /* background-color: purple; */
        height: 70%;
    }
}

@media screen and (min-width: 1173px) and (max-width: 1401px) {
    @-ms-viewport {
        width: 1173px;
    }
    .text-style {
        font-size: 1em;
    }
    .filler {
        top: 8.003%;
    }
    .progress-animate-sprite-windows {
        top: 0px;
    }
    .progress-bar-windows {
        /* background-color: blue; */
    }
}

@media screen and (min-width: 1401px) and (max-width: 1586px) {
    @-ms-viewport {
        width: 1401px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite-windows {
        top: -1.8px;
        height: 26px;
        right: -0.7%;
    }
    .progress-bar-windows {
        /* background-color: darkblue; */
        height: 78%;
    }
}


@media screen and (min-width: 1600px) and (max-width: 1760px) {
    @-ms-viewport {
        width: 1600px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite-windows {
        top: 0px;
        right: -0.7%;
    }
    .progress-bar-windows {
        /* background-color: palegreen; */
    }
}
/*
@media screen and (min-width: 1586px) and (max-width: 1760px) {
    @-ms-viewport {
        width: 1586px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite {
        top: 4px;
        right: -0.7%;
    }
} */

@media screen and (min-width: 1760px) and (max-width: 1960px) {
    @-ms-viewport {
        width: 1760px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite-windows {
        top: 5.5px;
        height: 26px;
        background-repeat: no-repeat;
        right: -0.7%;
    }
    .progress-bar-windows {
        /* background-color: black; */
    }
}

@media screen and (min-width: 1960px) and (max-width: 2230px) {
    @-ms-viewport {
        width: 1960px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-bar-windows {
        /* background-color: white; */
    }
    .progress-animate-sprite-windows {
        top: 5.5px;
        height: 26px;
        background-repeat: no-repeat;
        right: -0.7%;
    }

}
@media screen and (min-width: 2230px) and (max-width: 2640px) {
    @-ms-viewport {
        width: 2230px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-animate-sprite-windows{
        top: -2.5px;
        height: 27px;
        background-repeat: no-repeat;
        right: -0.4%;
    }
    .progress-bar-windows {
        /* background-color: orange; */
        height: 51%;
    }
}

@media screen and (min-width: 2640px) and (max-width: 3082px) {
    @-ms-viewport {
        width: 2640px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-bar-windows {
        /* background-color: green; */
    }
    .progress-animate-sprite-windows{
        top: 3.5px;
        height: 27px;
        background-repeat: no-repeat;
        right: -0.4%;
    }
}

@media screen and (min-width: 3082px) and (max-width: 3174px) {
    @-ms-viewport {
        width: 3082px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-bar-windows {
        /* background-color: yellow; */
    }
}

@media screen and (min-width: 3174px) and (max-width: 3219px) {
    @-ms-viewport {
        width: 3082px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-bar-windows {
        /* background-color: red; */
    }
    .progress-animate-sprite-windows{
        top: 3.5px;
        height: 27px;
        background-repeat: no-repeat;
        right: -0.4%;
    }
}

@media screen and (min-width: 3219px) and (max-width: 3520px) {
    @-ms-viewport {
        width: 3219px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-bar-windows {
        /* background-color: purple; */
    }
    .progress-animate-sprite-windows{
        top: 0.5px;
        height: 27px;
        background-repeat: no-repeat;
        right: -0.4%;
    }
}

@media screen and (min-width: 3521px) {
    @-ms-viewport {
        width: 3520px;
    }
    .text-style {
        margin-top: -1.7%;
    }
    .progress-bar-windows {
        /* background-color: violet; */
        height:46%;
    }
    .progress-animate-sprite-windows{
        top: 3.5px;
        height: 27px;
        background-repeat: no-repeat;
        right: -0.4%;
    }
}
