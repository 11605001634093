

.high-lo-small-icon-placeholder {
  width: 28%;
  height: 24%;
  left: 35%;
  top: -10.3%;
  position: absolute;
}


  .hi_img{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation:  slideInRight 1.2s;
    -webkit-animation: slideInRight 1.2s;
    -moz-animation: slideInRight 1.2s;
    -o-animation: slideInRight 1.2s;
  }

  .hi_img_placeholder{
    width: 29%;
    height: 51%;
    left: 8%;
    position: absolute;
    top: 24%;

  }

  .lo_img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation:  slideInLeft 1.2s;
    -webkit-animation: slideInLeft 1.2s;
    -moz-animation: slideInLeft 1.2s;
    -o-animation: slideInLeft 1.2s;
  }

  .lo_img_placeholder{
    width: 32%;
    height: 53%;
    left: 60%;
    position: absolute;
    top: 21%;

  }

  .up_img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: bounceInUp 2s;
    -webkit-animation: bounceInUp 2s;
    -moz-animation: bounceInUp 2s;
      -o-animation: bounceInUp 2s;
  }

  .up_img_placeholder {
      width: 88%;
    height: 56%;
    left: 12%;
    position: absolute;
    top: 15%;
  }

  .down_img{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: bounceInDown 2s;
    -webkit-animation: bounceInDown 2s;
    -moz-animation: bounceInDown 2s;
    -o-animation: bounceInDown 2s;
  }

  .down_img_placeholder {
        width: 88%;
    height: 66%;
    left: 12%;
    position: absolute;
    top: 56%;
  }

  .arrow_placeholder {
    width: 21%;
    height: 42%;
    left: 38%;
    position: absolute;
    top: 17%;

  }

  .game_placeholder {
    width: 28%;
    height: 38%;
    left: 38%;
    position: absolute;
    top: 76%;
  }

  .game_placeholder_title {
    width: 28%;
    height: 38%;
    left: 37%;
    position: absolute;
    top: 67%;
  }
  .game_placeholder_try_again{
     width: 28%;
    height: 38%;
    left: 37%;
    position: absolute;
    top: 64%;
  }


  .game_text {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: fadeIn .5s;
    -webkit-animation: fadeIn .5s;
    -moz-animation: fadeIn .5s;
    -o-animation: fadeIn .5s;
  }


@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft 1.2s;
  animation-name: slideInLeft 1.2s;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1000px, 0);
    transform: translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1000px, 0);
    transform: translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -1000px, 0);
    transform: translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}



.hi_lo_game_main_placeholder{
    width: 51%;
    height: 39%;
    left: 25%;
    position: absolute;
    top: 23%;
}

.hi_lo_game_try_again_placeholder{
      width: 57%;
    height: 48%;
    left: 23%;
    position: absolute;
    top: 23%;
}
.hi_lo_game_lose_placeholder{
width: 88%;
height: 86%;
margin-left: 5%;
position: absolute;
top: -9%;
}

.hi_img_placeholder_1{
  width: 32%;
  height: 61%;
  left: 9%;
  position: absolute;
  top: 22%;
}

.hi_img_placeholder_2{
  width: 32%;
  height: 53%;
  left: 7%;
  position: absolute;
  top: 22%;
}


.lo_img_placeholder_1{
  width: 34%;
  height: 62%;
  left: 63%;
  position: absolute;
  top: 19%;

}


.arrow_placeholder_1 {
  width: 23%;
  height: 63%;
  left: 40%;
  position: absolute;
  top: 14%
}
