@import './variables.css';
@import url('https://fonts.googleapis.com/css?family=Lora:400,700|Titillium+Web:400,700');
@import-normalize;

:global(body) {
    font-size: var(--font-size-base);
    font-family: 'Titillium Web', Arial, Sans-Serif;
}

.wrapper {
    margin: auto;
    max-width: 100%;
    width: 100%;
    height: 100%;

    h1 {
        font-size: var(--font-size-h1);
    }
}

.reactLogo {
    vertical-align: middle;
    height: 48px;
    width: 48px;
}


.main-lobby-parent-div {
    position: relative;
    height: 100%;
    width: 80%;
    text-align: center;
    margin-left: 10%;
}


.middle-parent-div {
    margin: 0 !important;
    padding: 0;
    width: 100%;
}
