
.lobby_notready_placeholder{
    text-align: center;
    width: 100%;
    position: absolute;
    right: 18%;
    height: 46%;
    z-index: 2;
}
.lobby_notready_placeholder_other{
    text-align: center;
    position: absolute;
    right: 18%;
    width: 100%;
    height: 46%;
    z-index: 2;
}
.blanck_screen_error{
    top: 10%;
    z-index: 9;
}
.other_screen_error{
    top: 30%;
}


.text_2 {
    margin-left: 21%;
    font-family: 'robotoBlack';
    color: #ffffff;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}



.text1{
    margin-left: 18%;
    width: 93%;
    font-family: 'robotoBlack';
    color: #ffffff;
    font-size: 1.5em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.text_wifi{
    width:93%;
    margin-top: 0px;
    font-size: 1em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;

}

.text_seam_err{
    width:93%;
    margin-top: 0px;
    font-size: 1em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;

}

.text3{
    width:93%;
    margin-left: 18%;
    font-size: 1em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.button_container{
    position: relative;
    left: 3%;
}


.text_reload{
    width: 68%;
    margin-left: 12%;
        font-size: 1em;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;

}

.button{
    font-family: 'robotoBlack';
    margin-left: 37%;
    background: #35e340;
    background-image: -webkit-linear-gradient(top, #35e340, #23a317);
    background-image: -moz-linear-gradient(top, #35e340, #23a317);
    background-image: -ms-linear-gradient(top, #35e340, #23a317);
    background-image: -o-linear-gradient(top, #35e340, #23a317);
    background-image: linear-gradient(to bottom, #35e340, #23a317);
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    text-shadow: 0px 6px 11px #333333;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.5em;
    margin-top: 5%;
 }
.button:hover {
    background: #65e66b;
    background-image: -webkit-linear-gradient(top,#3f8a36, #65e66b);
    background-image: -moz-linear-gradient(top,#3f8a36, #65e66b);
    background-image: -ms-linear-gradient(top,#3f8a36, #65e66b);
    background-image: -o-linear-gradient(top,#3f8a36, #65e66b);
    background-image: linear-gradient(to bottom,#3f8a36, #65e66b);
    text-decoration: none;
}

.button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.imgblankscreen{
    /* background-image: url('./images/blankscreen.png'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
}
.text_blankscreen{
    width:93%;
    color: #f0f006;
    margin-top: 0px;
    font-size: 1.4em;
    text-shadow: none;
    background-color: #04043c;
}

.messageClass{
    font-size: .6em;
margin-bottom: 5%;
}

.form-signin {
 width: 100%;
/* margin-left: 1%; */
}

.login{
margin-bottom: -1%;
}

.form-label-group{
margin-bottom: 5%;
}

.link-primary{
/*font-style: italic;*/
margin-top: 4%;
}

.note-primary{
margin-top:16%;
margin-bottom: 0%;
}

.requiredInfo{
  color: blue;
  /*font-style: italic;*/
  margin-bottom: 2%;
  margin-top: 1%;
  text-align:left;
}

.error{
    color:#fd8e62;
    text-align: left;
}

.success{
    color:white;
}


@font-face {
    font-family: 'barlowbold';
    src: url('./images/barlow-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.fontfamily {
    font-family: 'barlowbold';
    color: white;
}

 .form-control{
    background-color : #061361 !important;
    color: #4f8cc5 !important;
    font-weight: bold;
}
 .form-control:focus {
    background-color : #061361 !important;
    color: #4f8cc5 !important;
}
 .card-signin{
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.card {
    background-size: 100% 100% !important;
    background-color: unset;
}
.new-account {
    color: white;
}

input[type="text"]::placeholder ,  input[type="password"]::placeholder,  input[type="email"]::placeholder{ /* Firefox, Chrome, Opera */
    color: #4f8cc5;
    opacity: 1;
}
input[type="text"]::-ms-input-placeholder ,  input[type="password"]::-ms-input-placeholder,  input[type="email"]::-ms-input-placeholder{ /* Firefox, Chrome, Opera */
    color: #4f8cc5;
}
input[type="text"]:-ms-input-placeholder ,  input[type="password"]:-ms-input-placeholder,  input[type="email"]:-ms-input-placeholder{ /* Firefox, Chrome, Opera */
    color: #4f8cc5;
}
.form-control{
    border: 1px solid #6015c6 !important;
    border-bottom: 1px solid #0e61c6 !important;
    box-shadow: none !important;
}
.form-control:focus {
    border: 1px solid #6015c6 !important;
    border-bottom: 1px solid #0e61c6 !important;
    box-shadow: none !important;
}
.form-label-group-a > a {
    color: #9ef7ff !important;
}
.custom-control-label {
    color: white !important;
}
a.create-account {
    color: #9ef7ff;
}
.button_style {
    background-size: 100% 100%;
    box-shadow: none;
    border: none;
    color: white;
    font-family: 'barlowbold';
    margin-top: 4%;
    background-color: transparent;
}
