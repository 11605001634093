html {
    height: 100%;
}
body {
    height: 100%;
    background-color: #010B40;
}

@font-face {
    font-family: 'robotoBlack';
    src: url('../common/fonts/Roboto-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.main-lobby-parent-div {
    position: relative;
    height: 100%;
    width: 99.5%;
    text-align: center;
    margin-left: 0%;
    z-index: 1;
}
.main-lobby-parent-div-enlarge {
    position: relative;
    height: 90%;
    width: 90%;
    text-align: center;
    margin-left: 1%;
    z-index: 1;
}
.header-parent-div {
    height: 0%;
    overflow: hidden;
}
.middle-parent-div {
    max-height: 82%;
    height:82%;
    overflow: visible;
    position: absolute;
    z-index: 3;
}

.footer-parent-div {
position: absolute;
top:50%;

}

.profile-pic-placeholder {max-width:51px; max-height:51px; }
div.profile-pic-placeholder::after {max-width:51px; width:100%;height:0;padding: 0 0 100% 0;}
.home-pic-placeholder {max-width:45px; max-height:51px;  animation: blinkingText 1s;
     -webkit-animation: blinkingText 1s;
     -moz-animation: blinkingText 1s;
     -o-animation: blinkingText 1s;}
.home-pic-placeholder::after {content: '\00a0';display: inline-block; width:51px; height:51px; background-position: 27.81316348195329% 0.34934497816593885%;background-size: 110%;background-image: url('./images/home_icon.png');padding: 0; background-repeat: no-repeat }
div.home-pic-placeholder::after {max-width:51px; width:100%;height:0;padding: 0 0 100% 0;}
.home-pic-placeholder-disabled {max-width:40px; max-height:51px; pointer-events:none;flex:1 }
.home-pic-placeholder-disabled::after {content: '\00a0';display: inline-block; width:51px; height:51px; background-position: 27.81316348195329% 0.34934497816593885%;background-size: 110%;background-image: url('./images/home-grey.png');padding: 0; background-repeat: no-repeat }
div.home-pic-placeholder-disabled::after {max-width:51px; width:90%;height:0;padding: 0 0 100% 0;}



.middle-parent-div {
    margin: 0 !important;
    padding: 0;
    width: 100%;
    max-width: 100%;
}
.frame-bg {
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    height: 100%;
    /* top: -1%; */
}
.frame-bg-gsn {
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    height: 100%;
    /* top: -1%; */
}

.frame-bg-acctInfo {
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    height: 100%;
    /* top: -1%; */
}
.top-hud-parent-div {
    height: 10%;
    position: relative;
}
.full-height-divs {
    height: 100%;
}
.profile-picture-div {
    width: 28%;
    margin-top: 8%;
    position: absolute;
    left: 4%;
}

.right-panel-div {
    width: 20%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 80%;
}
.bottom-hud-parent-div {
    position: relative;
    background-color: #928776;
    opacity: 0.6;
    width: 100%;
    height: 25%;
}
.mantle-placeholder {
    height: 90%;
    width: 60%;
    margin-top: 10%;
    margin-left: 20%;
    background-color: #987654;
}
.mantle-sample-image-style {
    width: 100%;
    height: 100%;
}
.left-arrow-placeholder {
    height: 12%;
    width: 6%;
    position: absolute;
    top: 44%;
    left: 0;
}
.profile-pic-icon{
           width: 82%;
    height: 57%;
        background-size: 100% 100%;
        border-radius: 30%;
}
.home-icon-placeholder{
    width: 61%;
    margin-top: 13%;
    margin-left: 14%;
    position: absolute;
    z-index: 3;
}
.home-pic-icon{
    background: url('./images/home_icon.png') no-repeat ;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}

.home-pic-disabled{
    background: url('./images/home-grey.png') no-repeat ;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}
.profile-pic-placeholder {
    width: 28%;
    margin-top: 7%;
    margin-left: 0%;
    position: absolute;
    left: 4%;
    top: 29%;
    left: 3%;
}
.home-pic-placeholder {

  width:90%;
}
.coin-placeholder{

    width: 65%;
    height: 45%;
    background-size: 100% 100%;
    margin-top: -4%;
}

.token-placeholder{

    color: #ffffff;
    font-family: 'robotoBlack';
    font-size: 1.2em;
    font-weight: bold;
    /* letter-spacing: 1px; */
}

.add-icon-placeholder{
    position: absolute;
    width: 23%;
    left: 9%;
    top: 35%;

}


.credit-icon-placeholder{
    position: absolute;
    width: 23%;
    left: 66%;
    top: 40%;

}

.search-icon-placeholder{
    position: absolute;
    width: 30%;
    left: -19%;
    top: 32%;
    cursor: pointer;
}

.menu-icon-placeholder{
    position: absolute;
    width: 36%;
    left: 28%;
    top: 32%;

}



.footer-placeholder{
    width: 100%;
    top: 83%;
    position: absolute;
    left: auto;
    height: 12%;
}

.footer-placeholder-container{
    width: 100%;
    top: 83%;
    position: absolute;
    left: auto;
    height: 12%;
}
.footer-placeholder-container-1920{
    width: 100%;
    top: 85%;
    position: absolute;
    left: auto;
    height: 12%;
}

.footer-placeholder-container-1440{
    width: 100%;
    top: 80.5%;
    position: absolute;
    left: auto;
    height: 12%;
}
.footer-placeholder-container-1280{
    width: 100%;
    top: 83%;
    position: absolute;
    left: auto;
    height: 12%;
}
.footer-placeholder-container-1025{
    width: 100%;
    top: 83%;
    position: absolute;
    left: auto;
    height: 12%;
}


.social-icons {
    display: block;
    margin: 10px 7px;
    text-align: center;
}
.support-links {
    margin: 10px 7px;
    text-align: left;
    color: #ffffff;
}

.sprite-footer {
    margin-left: 6px;
}
.icon {
    display: inline-block;
    vertical-align: top;
}
.footer-fb {
    background-position: 0 0;
    width: 45px;
    height: 45px;
}
.footer-google {
    background-position: 0 -45px;
    width: 45px;
    height: 45px;
}

.footer-instagram {
    background-position: 0 -90px;
    width: 45px;
    height: 45px;
}

.footer-twitter {
    background-position: 0 -135px;
    width: 45px;
    height: 45px;
}

.footer-youtube {
    background-position: 0 -180px;
    width: 45px;
    height: 45px;
}
.footer-linkedin {
    background-position: 0 -225px;
    width: 45px;
    height: 45px;
}

.footer-apple-store {
    background-position: 0 -269px;
    width: 150px;
    height: 44px;
    background-size: 100%;
    margin-left: 28px;
}
.footer-google-play {
    background-position: 0 -314px;
    width: 131px;
    height: 37px;
    background-size: 115%;
    transform-origin: top;
    transform: scaleY(1.18);
    border-top-right-radius: 6px;
}
.footer-amazon {
    background-position: 0 -349px;
    width: 131px;
    height: 37px;
    background-size: 114%;
    transform-origin: top;
    transform: scaleY(1.18);
}

.fb-like-container {
    display: inline-block;
    position: relative;
    width: 49px;
    height: 17px;
    margin-right: 3px;
    vertical-align: bottom;
}

.link {
    color: #fff;
    font-size: 12px;
    border-right: 1px solid #ffffff;
    margin-right: 5px;
    padding-right: 5px;
    cursor: pointer;
}

.myAccount {
    color: #fff;
    font-size: 12px;
    border-right: 1px solid #ffffff;
    margin-right: 5px;
    padding-right: 5px;
}
.myAccount:hover{
    color: #0041ae;
    text-decoration: underline;
    cursor: pointer;
}
.logout {
    color: #fff;
    font-size: 12px;
    border-right: 1px solid #ffffff;
    margin-right: 5px;
    padding-right: 5px;
}
.logout:hover{
    color: #0041ae;
    text-decoration: underline;
    cursor: pointer;
}
.copyright {
    float: right;
    font-size: 12px;
}

.game-container-style{
    z-index: -1;
    background-color: transparent ;
    position: absolute;
    width: 100%;
    height: 100%;
}

.game-placeholder{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.game-height{
    max-height: 94%;
    height: 94%;
}


  button {
    margin-right: 10px;
    padding: 7px 12px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    outline: 0;
  }
  .buy_tokens_button{
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    margin-left: -2%;
  }

  .bg-check{
    width: 1300px;
    height: 675px;
    background-size: 100% 100%;
    background-color: #928776;
    opacity: 0.5;
    position: absolute;
    z-index: 2;
  }


.progress_bar_placeholder{
    /* width: 79.3%;
    height: 9%;
    position: absolute;
    z-index: 8;
    top: 86%;
    left: 9%; */

    width: 88.5%;
    height: 9.5%;
    position: relative;
    z-index: 3;
    top: 73%;
    left: 7%;

}
.pre_lobby_loader_height{

    max-height: 100%;
    height: 100%;

}


  .gamecontainer{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index:0;
    background-color: transparent !important;
  }
  .gamecontainer canvas{
    border-radius:5px;
  }

  .unitycanvas {
    width: 100%;
    height: 100%;
    z-index:0;
    background-color: transparent !important;
    border-radius:5px;
  }
  .lobby-container{
        height: 1401px;
        width: 830px;
        -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
  }

.flex-style-1{
    display:-webkit-flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:flex;
    justify-content: center;
    align-items: center;
    -webkit-flex:1;
    -webkit-box-flex:1;
    -moz-flex:1;
    -moz-box-flex:1;
    -ms-flex:1;
    flex:1;
}
.flex-style-generic{
    display:-webkit-flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:flex;
    justify-content: center;
    align-items: center;
    -webkit-flex:1;
    -webkit-box-flex:1;
    -moz-flex:1;
    -moz-box-flex:1;
    -ms-flex:1;
    flex:1;
}

.loading_Game_tiles{
    width: 97%;
    height: 22%;
    left: 2%;
    position: absolute;
    z-index: 4;
    top: 14%;
}

.game_tiles{
    width: 100%;
    height: 239px;
    background-size: 100% 100%;
}

.game_text{
    background-color: transparent;
    color: #fff;
    margin-top: 0%;
    font-family: 'robotoBlack';
    font-weight: normal;
    font-style: normal;
    font-size: 2.5em;
    text-shadow: 3px 3px #07001b;
}
.game_text_small{
    background-color: transparent;
    color: #9df7ff;
    font-family: 'robotoBlack';
    font-weight: normal;
    font-style: normal;
    font-size: 1.8em;
    text-shadow: 3px 3px #07001b;
}

.loading_game_text{
    /* background-color: #030127; */
    background-color: transparent;
    color: #fff;
    font-family: 'robotoBlack';
    font-weight: normal;
    font-style: normal;
    font-size: 1.4em;
    text-shadow: 2px 2px #07001b;
}
.loading_game_text_container{
    margin-top: 5%;
    z-index: 15;
}

.reached_max_tokens{
    width: 84%;
    height: 22%;
    left: 8%;
    position: absolute;
    z-index: 4;
    top: 30%;
}

.max_tokens{
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}


.skip_placeholder{
      width: 6%;
    height: 4%;
    left: 72%;
    position: absolute;
    z-index: 4;
    top: 77%;
    animation: blinkingText 2s infinite;
    -webkit-animation: blinkingText 2s infinite;
    -moz-animation: blinkingText 2s infinite;
    -o-animation: blinkingText 2s infinite;
}
.skip_button{
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}

@keyframes blinkingText{
	0%{		opacity: 0;	}
	49%{	opacity: 1;		}
	50%{	opacity: 0; }
	99%{	opacity: 1;	}
	100%{	opacity: 1;	}
}


@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@media screen and (min-width: 978px) and (max-width: 1100px){
    @-ms-viewport { width: 978px; }
    .token-placeholder{
        font-size: 1em;
    }
    .home-icon-placeholder{
        background-color: navajowhite;
    }
}


@media screen and (min-width: 751px) and (max-width: 880px) {
    @-ms-viewport {
        width: 841px;
    }

    .progress-animate-sprite {
        top: -2px;
    }
    .top-hud-parent-div {
        /* background-color: rebeccapurple; */
        left:10px;
    }
    .token-placeholder{
        font-size: 1em;
    }
}
@media screen and (max-width: 841px) {
    @-ms-viewport {
        width: 320px;
    }

    .top-hud-parent-div {
        /* background-color: violet; */
        left:10px;

    }
    .token-placeholder{
        font-size: 1em;
    }
}

@media screen and (min-width: 880px) and (max-width: 978px) {
    @-ms-viewport {
        width: 880px;
    }

    .top-hud-parent-div {
        /* background-color: black; */
        left: 7px;
    }
    .token-placeholder{
        font-size: 1.1em
    }
}

@media screen and (min-width: 978px) and (max-width: 1100px) {
    @-ms-viewport {
        width: 978px;
    }


    .top-hud-parent-div {
        /* background-color: antiquewhite; */
        left:5px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1173px) {
    @-ms-viewport {
        width: 1100px;
    }

    .top-hud-parent-div {
        /* background-color: black; */
        left: 7px;
    }
}

@media screen and (min-width: 1173px) and (max-width: 1401px) {
    @-ms-viewport {
        width: 1173px;
    }

    .top-hud-parent-div{
        /* background-color: #410352; */
        left: 5px;

    }
    .token-placeholder{
        font-size: 1em;

    }
}

@media screen and (min-width: 1401px) and (max-width: 1586px) {
    @-ms-viewport {
        width: 1401px;
    }

    .top-hud-parent-div{
        /* background-color: aliceblue; */
        left:10px;
    }
}

@media screen and (min-width: 500px) and (max-width: 690px) {
    @-ms-viewport {
        width: 1600px;
    }

    .top-hud-parent-div {
        /* background-color: palegreen; */
    }
}

@media screen and (min-width: 1680px) and (max-width: 1760px) {
    @-ms-viewport {
        width: 1600px;
    }

    .top-hud-parent-div {
        /* background-color: palegreen; */
    }

}

@media screen and (min-width: 1760px) and (max-width: 1960px) {
    @-ms-viewport {
        width: 1760px;
    }

    .top-hud-parent-div{
        /* background-color: red; */
    }
}

@media screen and (min-width: 1960px) and (max-width: 2230px) {
    @-ms-viewport {
        width: 1960px;
    }


    .top-hud-parent-div{
        /* background-color: #410352; */
        left: 10px;

    }

}
@media screen and (min-width: 2230px) and (max-width: 2640px) {
    @-ms-viewport {
        width: 2230px;
    }

    .top-hud-parent-div {
        /* background-color: paleturquoise; */
        left: 10px;
    }
}

@media screen and (min-width: 2640px) and (max-width: 3082px) {
    @-ms-viewport {
        width: 2640px;
    }
    .top-hud-parent-div{
        /* background-color: #410352; */
        left: 10px;

    }
    .token-placeholder{
        font-size: .1em;

    }
}

@media screen and (min-width: 3082px) and (max-width: 3174px) {
    @-ms-viewport {
        width: 3082px;
    }

    .top-hud-parent-div {
        /* background-color: palevioletred; */
    }
}

@media screen and (min-width: 3174px) and (max-width: 3219px) {
    @-ms-viewport {
        width: 3082px;
    }

    .top-hud-parent-div {
        /* background-color: palegreen; */
    }
}

@media screen and (min-width: 3219px) and (max-width: 3520px) {
    @-ms-viewport {
        width: 3219px;
    }


    .top-hud-parent-div {
        /* background-color: powderblue; */
        left: 10px;
    }

    .token-placeholder{
        font-size:1em;
    }
}

@media screen and (min-width: 3521px) {
    @-ms-viewport {
        width: 3520px;
    }

    .top-hud-parent-div {
        /* background-color: pink; */
    }

}

.migrationPopupBg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 111;
    background: url("./images/MIgration_popup_bg.jpg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    text-align: center;
}

.redirectionpopup {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 111;
    background: url("./images/Redirection-banner_popup.jpg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    text-align: center;
}

.headerBg {
    width: 37%;
    margin-top: 2%;
}
.maintext {
    font-size: 1.7em;
    width: 80%;
    text-align: center;
    color: white;
    margin-left: 10%;
    margin-top: 4%;
    font-weight: bold;
    font-family: 'robotoBlack';
    white-space: nowrap;
    text-shadow: 0 0 3px #0d68d7, 0 0 3px #0d68d7, 0 0 3px #0d68d7, 0 0 3px #0d68d7, 0 0 3px #0d68d7, 0 0 3px #0d68d7,0 0 3px #0d68d7, 0 0 3px #0d68d7, 0 0 3px #0d68d7, 0 0 3px #0d68d7, 0 0 3px #0d68d7, 0 0 3px #0d68d7;
}
.secondtext {
    margin-top: 4%;
    display: block;
}
.newwebtext {
    background: url("./images/text_ol.png");
    font-size: 3em;
    width: 50%;
    text-align: center;
    color: #fffa7b;
    margin-left: 25%;
    margin-top: 2%;
    font-weight: bold;
    font-family: 'robotoBlack';
    text-shadow: 0 0 3px #b60475, 0 0 3px #b60475, 0 0 3px #b60475, 0 0 3px #b60475, 0 0 3px #b60475, 0 0 3px #b60475,0 0 3px #b60475, 0 0 3px #b60475, 0 0 3px #b60475, 0 0 3px #b60475, 0 0 3px #b60475, 0 0 3px #b60475;
    z-index: 112;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;

}
.letsGoButton {
    width: 23%;
    margin-top: 4%;
    cursor: pointer;
}
.closebutton {
    cursor: pointer;
    position: absolute;
    top: 1%;
    right: 0;
    width: 6%;
}
.toggleJSButton {
    cursor: pointer;
    color: white;
}
