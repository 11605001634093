
.text_2 {
    font-family: 'robotoBlack';
    font-size: 3.5em;
    color: #ffffff;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}

.text_placeholder{
    top: 25%;
    position: absolute;
    left: 33%;
    width: 40%;
    height: 11%;

}

.almost_there{
    /*background-image: url('./images/text-03.png');*/
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: zoomIn 1s;
	-webkit-animation: zoomIn 1s;
	-moz-animation: zoomIn 1s;
	-o-animation: zoomIn 1s;
}



@keyframes zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
  }



  @-webkit-keyframes heartBeat {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    14% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }

    28% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    42% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }

    70% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
