
.lets_play_button{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: zoomIn;
  -webkit-animation: zoomIn .3s;
  -moz-animation: zoomIn .3s;
  -o-animation: zoomIn .3s;
}

.lets_play_button_placeholder{
    position: absolute;
    z-index: 0;
    width: 16%;
    height: 11%;
    top: 69%;
    left: 42%;
}

.game_icon{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: fadeIn .5s;
    -webkit-animation: fadeIn .5s;
    -moz-animation: fadeIn .5s;
    -o-animation: fadeIn .5s;

}

@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
@keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

.game_icon_placeholder{
  position: absolute;
  z-index: 0;
  width: 59%;
  height: 69%;
  top: 14%;
  left: 20%;
}

.hi_lo_game_title_placeholder{
  width: 66%;
  height: 54%;
  margin-left: 16%;
  position: absolute;
  top: 20%;
}

.text_placeholder{
     width: 49%;
    /* height: 92%; */
    padding-top: 6%;
    margin-left: 28%;
  animation: zoomIn 1s;
  -webkit-animation: zoomIn 1s;
  -moz-animation: zoomIn 1s;
  -o-animation: zoomIn 1s;
}

.text_placeholder_free_tokens{
    width: 63%;
    height: 58%;
    padding-top: 3%;
    margin-left: 19%;
animation: zoomIn 1s;
  -webkit-animation: zoomIn 1s;
  -moz-animation: zoomIn 1s;
  -o-animation: zoomIn 1s;
}


@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}


  @media screen and (max-width: 841px){
  @-ms-viewport { width: 320px; }
    .lets_play_button_placeholder{
      left:42%;
    }


  }
