.err_top{
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    height: 100%;
    left:1%;
}

.err_content1{
    position: relative;
    color: aliceblue;
    text-align: center;
}

.logoImage {
    height: 25%;
}

.text_21 {
    text-align: center;
    font-family: 'robotoBlack';
    font-size: 2em;
    color: #ffffff;
    text-shadow: 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603,0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603, 0 0 3px #023603;
}
