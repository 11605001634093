.card_fields_container{
    /* background-color: white; */
    max-width: 98%;
    max-height: 80%;
    position: absolute;
    left: 28.5%;
    z-index: 999999999999;
    top: 0;
    /* border: 2px solid white; */
    /* height: 40%; */
    width: 70%;
    /* overflow: visible; */
    overflow: hidden;
    overflow-y: scroll;
}

.ftue_frame {
    left: -40.5%;
    margin-top: 0%;
    z-index: 5;
    background-size: 100%;
    width: 1100px;
    height: 720px;
    position: relative;
    background-color: #032c6e;
    opacity: 0.5;
}

.closeButton{
    color: red;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: right;
    width: 88%;
    position: absolute;
    top: 0%;
    left: 11%;
    z-index: 100;
}

.openButton{
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: right;
    width: 88%;
    position: absolute;
    top: 16%;
    z-index: 100;
}

.modal_content{
    position: absolute;
    top: 0.5%;
    left: 2.5%;
    width: 50.5%;
    z-index: 99;
    height: 200px;
    opacity: 1;
}
.modal{
    background-color: yellow;
}
#randomid {
    border-radius: 12px;
}
